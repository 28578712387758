import "./public-path.js";
import common from "#app/common.js";
import $ from "cash-dom";
import {storeQuery} from "@mono/node-starter/common/browser/url-query-storage.js";
import {uuid} from "@mono/node-starter/common/random.js";
import Cookie from "cookie-universal";

const cookies = Cookie();
common.storeTrackingParams();


(() => {
	const isAndroid = /(android)/i.test(navigator.userAgent);
	const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);
	document.documentElement.setAttribute("data-os", isAndroid ? "android" : (isIOS ? "ios" : "unknown"));
})();

const app = {
	$,
};
storeQuery({
	  // 14 days
	ttl: 14 * 24 * 60 * 60 * 1000,
});

const escapeRegExp = str => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
let baseUrl; // = window.location.origin + window.location.pathname;
function updateUrl () {
	const baseUrlNew = window.location.href.toString().replace(/(#[^\s]+)/ig, "");
	if (baseUrlNew !== baseUrl) {
		[...$("svg")].forEach(svg => {
			const $svg = $(svg);
			let html = $svg.html() || "";
			let newHtml = html;
			let rx;
			try {
				rx = new RegExp(escapeRegExp(`${window.location.origin}`) + ".*?\#", "igm");
				newHtml = html.replace(rx, baseUrlNew.replace(/\&/ig, "&amp;").split("#")[0] + "#");
			}
			catch (error) {
				console.error("svg fix error", error, rx);
			}
			if (newHtml !== html) {
				$svg.html(newHtml);
			}
		});
	}
	baseUrl = baseUrlNew;
}
setInterval(updateUrl, 500);

console.log("oldsite: page loaded.", window.location.href);
window.addEventListener("pageshow", (event) => {
	if (event.persisted) {
		console.log("oldsite: This page was restored from the bfcache.", window.location.href);
	}
	else {
		console.log("oldsite:  This page was loaded normally.", window.location.href);
	}
});
window.addEventListener("pagehide", (event) => {
	if (event.persisted) {
		console.log("oldsite: This page *might* be entering the bfcache.", window.location.href);
	}
	else {
		console.log("oldsite: This page will unload normally and be discarded.", window.location.href);
	}
});

const ga = window.ga;
window.ga = (...args) => {
	const [_act, _type, uaEvent] = args || [];
	ga?.(...args);

	if (_act === "send" && _type === "event" && window.dataLayer) {
		window.dataLayer.push({
			event: "gaEvent",
			uaEvent: {
				...uaEvent,
				siteClientId: window.siteClientId,
			},
		});
	}
};


window.siteClientId = cookies.get("site_clid");
if (!window.siteClientId) {
	window.siteClientId = uuid();
	cookies.set("site_clid", window.siteClientId);
}


const frontAjaxEnabled = document.querySelector(`meta[name="fraj"]`);
if (frontAjaxEnabled && frontAjaxEnabled.content == "false") {
	const dummy = () => new Proxy(() => {}, {
		get (target, p) {
			return dummy();
		},
		set () {

		},
		apply () {
			return dummy();
		},
	});

	window.fetch = () => {
		return dummy();
	};

	window.XMLHttpRequest = () => {
		return dummy();
	};
}

Promise.prototype.finally = function (callback) {
	return this.then(
		value => this.constructor.resolve(callback()).then(() => value),
		error => this.constructor.resolve(callback()).then(() => {throw error;}),
	);
};

function initExpander () {
	if (window.location.hash) {
		const hash = window.location.hash;
		updateHash(hash, true);
		// if ($el[0]) {
		// 	$(window).scrollTop($el.offset().top - 100);
		// 	window.location.hash = "#_";
		// 	history.replaceState(null, document.title, window.location.href.toString().replace(/#.*?$/igm, "") + hash);
		// }
	}
	function updateHash (hash, pop) {
		// const $expander = $(hash).closest("[expand]");
		// const $header = $expander.children(":nth-child(1)");
		// const $body = $expander.children(":nth-child(2)");
		// $(".expand[expand]").removeClass("expand").children(":nth-child(2)").animate({height: "0px", "display": ""}, 300);
		if (!pop && window.location.hash === hash) {
			window.location.hash = "#_";
			history.pushState(null, document.title, window.location.href.toString().replace(/#.*?$/igm, ""));
		}
		// else if ($expander[0]) {
		// 	// $expander.addClass("expand");
		// 	// $body.css({height: "0px", "display": ""}).animate({height: $body.get(0).scrollHeight}, 300).promise().then(() => {
		// 	// 	$body.css({height: "auto", "display": ""});
		// 	// });
		// 	!pop && history.pushState(null, document.title, window.location.href.toString().replace(/#.*?$/igm, "") + hash);
		// }
	}

	$(document).on("click", "[expand] > a", event => {
		event.preventDefault();
		const $a = $(event.target).closest("a");
		const hash = "#" + ($a.prop("href") || "").split("#")[1];
		updateHash(hash);
	});
	$(window).on("popstate", () => {
		const hash = window.location.hash;

		updateHash(hash, true);
	});
}

app.timings = {
	fastLoad: 1000,
	fullyHideLoader: 2000,
	removeLoading: 350,
};

app.finishLoad = () => {
	$(() => {
		setTimeout(() => {
			$(".full-screen-logo").removeClass("show");
		}, 50);

		setTimeout(() => {
			$(".loading-bar-main").addClass("finish");
		}, 270);

		setTimeout(() => {
			$("html, body").removeClass("loading");
			if (window.location.hash && !document.querySelector(".page-pdf") && !(window.location.hash.includes("="))) {
				try {
					const el = document.querySelector(window.location.hash);
					el && el.scrollIntoView();
				}
				catch {}
			}
			initExpander();
		}, app.timings.removeLoading);

		setTimeout(() => {
			$(".loading-bar-main").css({"display": "none"});
			$(".full-screen-logo").css({"display": "none"});
		}, app.timings.fullyHideLoader);
	});
};

app.startup = async (page, settings) => {
	async function doStartup () {
		console.log(`Executing scripts for "${page}" page`);
		const loadTime = +(new Date()) - window.startTime;
		try {
			[...document.querySelectorAll(`[data-smart-link="android"]`)]
				.forEach(el => {
					const $url = new URL(el.href);
					const trackingParams = common.getTrackingParams();
					Object.keys(trackingParams).forEach(key => {
						$url.searchParams.set(key, trackingParams[key]);
					});
					el.href = $url.toString();
				});

			[...document.querySelectorAll(`[data-smart-link="ios"]`)]
				.forEach(el => {
					const $url = new URL(el.href);
					const trackingParams = common.getTrackingParams();
					Object.keys(trackingParams).forEach(key => {
						$url.searchParams.set(key, trackingParams[key]);
					});
					el.href = $url.toString();
				});
		}
		catch {}

		$("body").addClass("loaded");
		if (loadTime < app.timings.fastLoad) {
			$("body").addClass("loaded-fast");
		}
		window.app = app;
		app.settings = settings;
		common.initModal();
		common.initImgLazyLoad();

		$("a.lang-selector--item").on("click", event => {
			event.preventDefault();
			const url = new URL(window.location.href);
			const target = event.target.closest("a");
			if (!target.matches(".active")) {
				const lang = target.dataset.lang;
				const mode = target.dataset.mode;
				console.log("LANG CLICK with mode", lang, mode);
				localStorage.siteLang = lang;
				if (mode === "frontend") {
					window.location.href = target.href;
				}
				else {
					url.searchParams.set("lang", lang);
					window.location.href = url;
				}

			}
		});


		$(document).on("click", "a[native-link]", event => {
			event.preventDefault();
			const $a = $(event.target).closest("a");
			const native = $a.attr("native-link");
			const href = $a.prop("href");
			const iframe = $(`<iframe style="position: absolute; height: 0px; width: 0px; border: 0px;"></iframe>`).appendTo("body");
			iframe.prop("src", "data:text/html;charset=utf-8," + escape(`
				<!doctype html>
				<html>
				<head>
				<script>
					window.location.replace("${native}");
				</script>
				</head>
				<body>
				</body>
				</html>
			`));
			setTimeout(() => {
				if (!document.hidden && document.hasFocus()) {
					const wnd = window.open(href);
					wnd.focus();
				}
				iframe.remove();
			}, 700);

		});

		$(document).on("blur", "input, textarea", event => {
			setTimeout(() => $(event.currentTarget).toggleClass("has-value", !!$(event.currentTarget).val()));
		});
		$(document).on("focus", "form[disabled]", event => {
			event.preventDefault();
			event.stopPropagation();
			$(event.target).blur();
		});
		$(document).on("click mousedown mouseup keydown keypress keyup", "form[disabled]", event => {
			event.preventDefault();
			event.stopPropagation();
		});
		$(document).on("click", ".lang-selector--with-dropdown .lang-selector", event => {
			const menu = $(event.currentTarget);
			if (event.target.classList.contains("active")) {
				event.preventDefault();
			}
			if (menu.hasClass("opened")) {
				return;
			}
			menu.addClass("opened");
			event.preventDefault();
			event.stopPropagation();
			$(document).on("click", removeOpenMenu);
		});
		function removeOpenMenu () {
			$(".header .lang-selector-new.opened, .header .lang-selector.opened, .header--common .lang-selector.opened").removeClass("opened");
			$(document).off("click", removeOpenMenu);
		}

		let toggleCardsMenuTm;
		function toggleCardsMenu () {
			$("body").toggleClass("no-scroll");
			clearTimeout(toggleCardsMenuTm);
			if (!$(".cards-modal").is(".x-animate-show")) {
				$(".cards-modal").removeClass("hidden");
				setTimeout(() => {
					$(".cards-modal").addClass("x-animate-show");
				}, 50);

			}
			else {
				$(".cards-modal").removeClass("x-animate-show");
				toggleCardsMenuTm = setTimeout(() => {
					$(".cards-modal").addClass("hidden");
				}, 1500);
			}
		}
		$(`[href="#card-menu"]`).on("click", event => {
			event.preventDefault();
			toggleCardsMenu();
		});
		$(".cards-modal .header-menu-button").on("click", event => {
			toggleCardsMenu();
		});
		$(".cards-modal--item.active").on("click", event => {
			event.preventDefault();
			toggleCardsMenu();
		});

		$(`.card-menu-dropdown`).on("click", event => {
			event.preventDefault();
			const a = event.currentTarget;
			$(a).toggleClass("active");
			$(a).parent().find("ul").toggleClass("active");
		});

		const $menuButton = $(".header .header-menu-button");
		function toggleMenu () {
			// menu.classList.add("open");
			// document.body.classList.add("menu-opened");
			$menuButton.toggleClass("m-close");
			const opened = $menuButton.is(".m-close");
			$("header.header").toggleClass("m-open", opened);
			$("html, body").toggleClass("menu-open", opened);
			$("body").toggleClass("no-scroll");
		}
		if (!$menuButton.is(".header-menu-button--custom")) {
			$menuButton.on("click", toggleMenu);
		}

		$(document).on("click", "[tabs] > *", event => {
			const $link = $(event.target);
			const idx = $link.index();
			const $tabsCtrl = $link.parent();
			const activeLinkClass = $tabsCtrl.attr("tabs-active-link");
			const activeClass = $tabsCtrl.attr("tabs-active");
			const $targets = $($tabsCtrl.attr("tabs"));
			$tabsCtrl.children().removeClass(activeLinkClass).eq(idx).addClass(activeLinkClass);
			[...$targets].forEach(target => {
				const $target = $(target);
				if ($target[0].hasAttribute("tab-by-idx")) {
					$target.children().removeClass(activeClass);
					[...$target.children()].forEach(tab => {
						const $tab = $(tab);
						if ($tab.attr("tab-idx") && $tab.attr("tab-idx").split(",").some(i => i == (idx + 1))) {
							$tab.addClass("active");
						}
					});
				}
				else {
					$target.children().removeClass(activeClass).eq(idx).addClass(activeClass);
				}
			});
			$(document).trigger("tabclick");
		});



		function fitWidth () {
			const $el = $("[data-fit-width]");
			[...$el].forEach(el => {
				const $el = $(el);
				$el.css({transform: "none", "width": "100%"});
				const w = $el.width();
				$el.css({width: ""});
				const cw = $el.width();
				const scale = w / cw;
				$el.css({transformOrigin: "0 0", transform: `scale(${scale})`});
			});
		}

		setInterval(() => {
			fitWidth();
		}, 1000);
		$(window).on("resize", () => {
			fitWidth();
		});

		$(".menu-button").on("click", () => {
			common.showModal(`
				<div class="modal basic-modal site-menu-modal">
					<div style="text-align: right; padding-bottom: 35px;">
						<a href="#" class="close-modal">&times;</a>
					</div>
					<div class="wrapper">
						${$("<div></div>").append($(".site-menu").clone()).html()}
					</div>
				</div>
			`);
			$(".modal.site-menu-modal .close-modal").one("click", event => {
				event.preventDefault();
				event.stopPropagation();
				common.hideModal();
			});
		});


		await common.preinit(page);
		let ctrl;
		try {
			ctrl = await (import(`./pages/${page}`).then(res => res.default));
		}
		catch (error) {
			console.log(`no controller for "${page}" page`);
			console.log(error);
		}
		if (ctrl) {
			await Promise.resolve(typeof ctrl === "function" ? ctrl() : ctrl);
		}

		await common.postinit(page, ctrl);
		app.finishLoad();
	}

	if (window.cssloaded) {
		doStartup();
	}
	else {
		window.addEventListener("cssload", doStartup);
	}

};

window.onStartup && window.onStartup(app);

export default app;
